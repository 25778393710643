<template>
    <div>

        <div class="form">
            <div class="titleStep">
                <h1>¿Tiene {{formData.petname}} alergia a algo?</h1>
                <p>Nuestros snacks cumplen con los más altos estándares de nutrición. Pero siempre se pueden requerir condiciones especiales.</p>
            </div>
            <div class="wrap">
                <div class="columns is-multiline">
                    <div class="column is-6">
                        <b-radio v-model="form.allergie"
                            name="name"
                            native-value="Pollo"
                            @input="hideAnotherInput"
                            :class="[form.allergie === 'Pollo' ? 'active' : '']"
                            >
                            Pollo
                        </b-radio>
                    </div>
                    <div class="column is-6">
                        <b-radio v-model="form.allergie"
                            name="name"
                            native-value="Pavo"
                            @input="hideAnotherInput"
                            :class="[form.allergie === 'Pavo' ? 'active' : '']"
                            >
                            Pavo
                        </b-radio>
                    </div>
                    <div class="column is-6">
                        <b-radio v-model="anotherOption"
                            name="name"
                            native-value="true"
                            @input="showAnotherInput"
                            :class="[anotherOption  ? 'active' : '']"
                            >
                            Otro
                        </b-radio>
                    </div>
                    <div class="column is-6">
                        <b-radio v-model="form.allergie"
                            name="name"
                            native-value="Nada"
                            @input="hideAnotherInput"
                            :class="[form.allergie === 'Nada' ? 'active' : '']"
                            >
                            Nada
                        </b-radio>
                    </div>
                    <div class="column is-12 v-column" v-if="$v.form.allergie.$error">
                        <p class="help is-danger">Alergia inválida</p>
                    </div>

                    <div class="column is-12" v-if="anotherOption">
                        <b-field>
                            <b-input v-model="form.allergie" placeholder="Alergia"></b-input>
                        </b-field>
                    </div>
                </div>
            </div>
            <b-button id="step-five-continue" type="is-primary nextButton" @click="nextStep" style="margin-top: 1.3rem">Continuar</b-button>
            <a class="backStep" @click="backStep">Volver</a>
            <div class="msg-sub">
                Si {{formData.petname}} tiene otras restricciones en su dieta, por favor ponte en contacto con nosotros antes de comprar para que podamos acomodar la mejor opción.
            </div>
        </div>
        
    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required} from 'vuelidate/lib/validators'
    import StepMixin from '../../mixins/StepMixin'

    export default {
        props: ['clickedNext', 'currentStep'],
        mixins: [validationMixin, StepMixin],
        data() {
            return {
                form: {
                    allergie: '',
                },
                anotherOption: false,
            }
        },
        computed: {
            formData(){
                return this.$parent.$parent.$parent.formData 
            }
        },
        validations: {
            form: {
                allergie: { required },
            }
        },
        methods : {
            hideAnotherInput(){
                this.anotherOption = false
            },
            showAnotherInput(){
                this.anotherOption = true
                this.form.allergie = null
            },
        }
    }
</script>