<template>
    <div>
        <div class="form">
            <div class="checkoutPage">
                <div class="columns is-multiline">
                    <div class="column is-4">
                        <div class="card card-left">
                            <div class="imgWrapper">
                                <img src="@/assets/box.png" alt="logo">
                            </div>
                            <div class="details">
                                <h3>
                                    TupiBox para {{formData.petname}}
                                </h3>
                                <div class="item">
                                    <p>{{formData.boxSize}} Box</p>
                                    <span>{{formData.originalPrice / multiplier | currency}} CLP</span>
                                </div>
                                <div class="item" v-if="formData.extraToy === 'Si'">
                                    <p>Juguete extra</p>
                                    <span>{{prices.extratoy | currency}} CLP</span>
                                </div>
                                <div class="item">
                                    <p>Plan</p>
                                    <span>x {{multiplier}} Meses</span>
                                </div>
                                <div class="item">
                                    <p>Envío</p>
                                    <span>Gratis</span>
                                </div>
                                <div class="item">
                                    <b-field class="mergeInputs">
                                        <template v-if="!loadingDiscountCode">
                                            <b-input v-model="discountCode" placeholder="Cupon de descuento" :disabled="disabledDiscount"></b-input>
                                            <button @click="applyDiscount" class="button is-primary" :disabled="disabledDiscount">Aplicar</button>
                                        </template>
                                        <div v-else class="loading-inline">
                                            <loader></loader>
                                        </div>
                                    </b-field>
                                </div>
                                <hr>
                                <div class="item">
                                    <p>Total</p>
                                    <span>{{formData.price | currency}} CLP</span>
                                </div>
                            </div>
                        </div>

                        <div class="warranty">
                            <img src="@/assets/warranty.png" alt="logo">
                            <p>
                                Si tu mascota no está 100% satisfecha con algun producto de TupiBox, te enviamos uno de regalo y logramos que funcione nuestra relación.
                            </p>
                        </div>
                        
                    </div>
                    <div class="column is-8">
                        <div class="card card-right">
                            <div class="titleStep">
                                <h1>Completa tus datos</h1>
                                <p>Detalles para realizar el envio.</p>
                            </div>
                            <b-field>
                                <b-input v-model.trim="payData.name" placeholder="Nombre completo"></b-input>
                            </b-field>
                            <div class="column is-12 v-column" v-if="$v.payData.name.$error">
                                <p class="help is-danger">Nombre inválido</p>
                            </div>
                            <b-field>
                                <b-input v-model.trim="payData.address" placeholder="Dirección Completa"></b-input>
                            </b-field>
                            <div class="column is-12 v-column" v-if="$v.payData.address.$error">
                                <p class="help is-danger">Dirección inválida</p>
                            </div>
                            <b-field>
                                <b-input v-model.trim="payData.city" placeholder="Ciudad"></b-input>
                            </b-field>
                            <div class="column is-12 v-column" v-if="$v.payData.city.$error">
                                <p class="help is-danger">Ciudad inválida</p>
                            </div>
                            <b-field>
                                <b-input v-model.trim="payData.phone" placeholder="Teléfono"></b-input>
                            </b-field>
                            <div class="column is-12 v-column" v-if="$v.payData.phone.$error">
                                <p class="help is-danger">Teléfono inválido</p>
                            </div>
                            <b-button type="is-primary nextButton" id="step-checkout-pay" @click="submitPayForm">Pagar con Mercado Pago</b-button>
                            <div class="logoPay">
                                <img src="@/assets/mercadopago.png" alt="logo">
                            </div>
                        </div>

                        <div class="backWrapper">
                            <a class="backStep" @click="backStep">Volver</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import Logo from '@/assets/mercadopago.png'
    import Loader from '../Loader.vue'
    import {validationMixin} from 'vuelidate'
    import {required} from 'vuelidate/lib/validators'
    import StepMixin from '../../mixins/StepMixin'
    import crypto from 'crypto'
    import shortid  from 'shortid'
    import uuidv4 from 'uuid/v4'
    import axios from 'axios'

    export default {
        props: ['clickedNext', 'currentStep'],
        mixins: [validationMixin, StepMixin],
        components : {
            Loader
        },
        data() {
            return {
                payload : {},
                payData : {},
                discountCode : '',
                disabledDiscount : false,
                loadingDiscountCode: false
            }
        },
        computed: {
            formData(){
                return this.$parent.$parent.$parent.formData 
            },
            multiplier (){
                try {
                    return this.$parent.$parent.$parent.formData.plan.slice(-1)
                } catch (error) {
                    return 1
                }
            },
            prices(){
                return this.$parent.$parent.$parent.configs.prices 
            }
        },
        validations: {
            payData: {
                name: { required },
                address: { required },
                city: { required },
                phone: { required },
            },
        },
        methods: {
          async getPlanId(discont) {
            let plan = this.formData.plan
            if (this.formData.extraToy === 'Si') {
              plan = plan + '+extratoy'
            }
            if (discont) {
              plan = plan + '+' + discont
            }
            let req = await this.axios({
              method: 'post',
              url: 'https://hook.us1.make.com/esj9pk1q6phwjr5aou5523h24k3fal6x',
              data: {
                plan: plan
              }
            })
            // Get Json response value of field id
            return req.data.id
          },
            async submitPayForm(){

                this.$v.$touch()
                if (!this.$v.$invalid) {
                    
                  let formData = this.formData
                    
                    this.payload = {
                        x_account_id : process.env.VUE_APP_TUPIBOX_PAYMENT_PUBLIC_KEY,
                        x_amount : formData.price,
                        x_currency : 'CLP',
                        x_reference : shortid.generate(),
                        x_customer_email : formData.email,
                        x_url_complete : 'https://go.tupibox.com/response?status=complete',
                        x_url_cancel : 'https://go.tupibox.com/response?status=cancel',
                        x_url_callback : 'https://go.tupibox.com/response?status=callback',
                        x_signature : '',
                        x_shop_country : 'CL',
                        x_session_id : uuidv4(),
                    }
                    
                    let allData = {
                        ...formData,
                        ...this.payData,
                        ...this.payload
                    }

                    let req = await this.axios({
                        method: 'post',
                        url: 'https://hook.integromat.com/axup7p6gl9zf8ugd1ayab5bb1xrobkek',
                        data: allData
                    })

                    let descripcion = 'TupiBox para ' + this.formData.petname + ', suscripción x ' + this.multiplier + ' meses';

                    this.formData.frequency = this.multiplier

                    let orderData = {
                      external_reference: this.payload.x_reference,
                      description: descripcion,
                      price: this.formData.price
                    }

                    if (this.formData.discountAmount) {
                      orderData.plan = await this.getPlanId(this.formData.discountAmount)
                    } else {
                      orderData.plan = await this.getPlanId()
                    }
                    this.renderPaymentBrick(orderData);

                }

            },
            async renderPaymentBrick(data) {
                let settings = {
                    initialization: {
                        amount: this.formData.price,
                        payer: {
                            email: this.payData.email,
                        },
                    },
                    customization: {
                        visual: {
                            style: {
                                theme: "default",
                            },
                        },
                        paymentMethods: {
                            maxInstallments: 1
                        },
                    },
                    callbacks: {
                        onReady: () => {
                        },
                        onSubmit: (payFormData) => {
                            return new Promise((resolve, reject) => {
                                let allData = {
                                    ...this.formData,
                                    ...this.payData,
                                    ...payFormData,
                                    ...this.payload,
                                  ...{description: data.description},
                                }
                                allData.planId = data.plan
                                  
                                fetch(process.env.VUE_APP_TUPIBOX_PAYMENT_API_URL + '/payment', {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify(allData),
                                })
                                .then((response) => response.json())
                                .then((response) => {
                                    document.querySelector(".overlay").style.visibility = "hidden";
                                    window.paymentBrickController.unmount();
                                    let result = {
                                      x_reference: this.payload.x_reference,
                                      x_account_id: this.payload.x_account_id,
                                      x_amount: this.formData.price,
                                      x_currency: "CLP",
                                      x_gateway_reference: "",
                                      x_result: "",
                                      x_test: "",
                                      x_timestamp: new Date().toLocaleString(),
                                      x_signature: "",
                                      gws_rd: "",
                                    }
                                    if (response.status === "authorized") {
                                      result.x_result = "completed";
                                      result.x_gateway_reference = response.id;
                                        this.$router.push({
                                          path: '/response',
                                          query: { status: 'completed', ...result }
                                        })
                                    } else {
                                        result.x_result = "failed";
                                        this.$router.push({
                                          path: '/response',
                                          query: { status: 'failed', ...result }
                                        })
                                    }
                                    resolve();
                                })
                                .catch((error) => {
                                    let result = {
                                      x_reference: this.payload.x_reference,
                                      x_account_id: this.payload.x_account_id,
                                      x_amount: this.formData.price,
                                      x_currency: "CLP",
                                      x_gateway_reference: "",
                                      x_result: "failed",
                                      x_test: "",
                                      x_timestamp: new Date().toLocaleString(),
                                      x_signature: "",
                                      gws_rd: "",
                                    }
                                    document.querySelector(".overlay").style.visibility = "hidden";
                                    window.paymentBrickController.unmount();
                                    this.$router.push({
                                      path: '/response',
                                      query: { status: 'failed', ...result }
                                    })
                                    reject();
                                });
                            });
                        },
                        onError: (error) => {
                            console.error(error);
                        },
                    },
                };
                document.querySelector(".overlay").style.visibility = "visible";
                document.querySelector(".overlay").addEventListener("click", () => {
                    document.querySelector(".overlay").style.visibility = "hidden";
                    window.paymentBrickController.unmount();
                });
                window.paymentBrickController = await this.$bricksBuilder.create(
                    "cardPayment",
                    "paymentBrick_container",
                    settings
                );
                let divLogo = document.querySelector('div.container-2EtaVo');

                if (divLogo) {
                  divLogo.insertAdjacentHTML('afterbegin', '<div><img src ="'+ Logo +'" alt="MercadoPago"/></div>');
                }

                let div = document.querySelector('div.row-3R86bN');

                if (div) {
                  div.insertAdjacentHTML('beforeend', '<div style="margin-top: 20px; max-width: 640px;">La informaci&oacute;n que ingresa est&aacute; encriptada con certificado SSL. Se utiliza la API segura de MercadoPago y ning&uacute;n dato de tarjeta se almacena en los servidores de Tupibox.</div>');
                }
            },
            async applyDiscount(){
                this.loadingDiscountCode = true

                let code = this.discountCode
                let { data: resCode } = await axios.get(process.env.VUE_APP_API_TUPIBOX + '/codes/' + code)

                if(resCode.status === 'success' && !this.formData.discountAmount){
                    if ((await this.getPlanId(resCode.data.amount)).length < 1) {
                      this.loadingDiscountCode = false
                      return
                    }
                    this.formData.price -= resCode.data.amount
                    this.formData.discountCode = this.discountCode
                    this.formData.discountAmount = resCode.data.amount
                    this.disabledDiscount = true
                    this.loadingDiscountCode = false
                }else{
                    this.loadingDiscountCode = false
                }
            }
        }
    }
</script>
