<template>
  <div>
    <div class="form">
      <div class="titleStep">
        <h1>Cuéntanos de tu perrito bonito</h1>
        <p>
          Responde unas preguntas básicas sobre tu mascota para que podamos
          crear la caja perfecta.
        </p>
      </div>

      <div class="wrap">
        <div class="columns is-multiline">
          <div class="column is-6">
            <b-radio
              v-model="form.sex"
              name="name"
              native-value="She"
              :class="[form.sex === 'She' ? 'active' : '']"
            >
              Ella se llama
            </b-radio>
          </div>
          <div class="column is-6">
            <b-radio
              v-model="form.sex"
              name="name"
              native-value="He"
              :class="[form.sex === 'He' ? 'active' : '']"
            >
              El se llama
            </b-radio>
          </div>
          <div class="column is-12 v-column" v-if="$v.form.sex.$error">
            <p class="help is-danger">Sexo inválido</p>
          </div>
        </div>
        <div class="columns is-multiline">
          <div class="column is-12">
            <b-field>
              <b-input
                v-model.trim="form.petname"
                placeholder="Nombre de tus mascota"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-12 v-column" v-if="$v.form.petname.$error">
            <p class="help is-danger">Nombre inválido</p>
          </div>
        </div>
      </div>
      <b-button
        id="step-one-continue"
        type="is-primary nextButton"
        @click="nextStep"
        >Continuar</b-button
      >
      <a class="backStep" @click="backStep">Volver</a>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import StepMixin from "../../mixins/StepMixin";
import crypto from "crypto";

export default {
  props: ["clickedNext", "currentStep"],
  mixins: [validationMixin, StepMixin],
  data() {
    return {
      form: {
        petname: "",
        sex: "",
      },
      payload: {},
    };
  },
  computed: {
    formData() {
      return this.$parent.$parent.$parent.formData;
    },
  },
  validations: {
    form: {
      sex: { required },
      petname: { required },
    },
  },
  methods: {},
};
</script>
