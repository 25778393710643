<template>
    <div>

        <div class="form">
            <div class="titleStep">
                <h1>Selecciona el tamaño de tu caja</h1>
                <p>En Tupibox tenemos la opción que más se adapta a ti</p>
            </div>
            <div class="card-selects">
                <div class="columns is-multiline pets is-centered">
                    <div class="column is-4">
                        <b-radio v-model="form.boxSize"
                            name="name"
                            native-value="Light"
                            :class="[form.boxSize === 'Light' ? 'active' : '']"
                            >
                            <img src="@/assets/small_box.png">
                            <h3>Pequeña</h3>
                            <span>{{prices.light_x1 | currency}} / caja</span>
                        </b-radio>
                    </div>
                    <div class="column is-4">
                        <b-radio v-model="form.boxSize"
                            name="name"
                            native-value="Normal"
                            :class="[form.boxSize === 'Normal' ? 'active' : '']"
                            >
                            <img src="@/assets/normal_box.png">
                            <h3>Normal</h3>
                            <span>{{prices.normal_x1 | currency}} / caja</span>
                        </b-radio>
                    </div>
                    <div class="column is-12 v-column" v-if="$v.form.boxSize.$error">
                        <p class="help is-danger">Tamaño inválido</p>
                    </div>
                </div>
            </div>

            <b-button id="step-one-continue" type="is-primary nextButton" @click="nextStep">Continuar</b-button>
        </div>
    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required} from 'vuelidate/lib/validators'
    import StepMixin from '../../mixins/StepMixin'
    import crypto from 'crypto'

    export default {
        props: ['clickedNext', 'currentStep'],
        mixins: [validationMixin, StepMixin],
        data() {
            return {
                form: {
                    boxSize: ''
                },
                payload : {}
            }
        },
        computed: {
            formData(){
                return this.$parent.$parent.$parent.formData 
            },
            prices(){
                return this.$parent.$parent.$parent.configs.prices
            }
        },
        validations: {
            form: {
                boxSize: { required },
            }
        },
        methods: {
            
        }
        
    }
</script>