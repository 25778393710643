import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import Buefy from 'buefy'
import '@/assets/scss/style.scss'


Vue.use(Buefy, {
  defaultIconPack: 'mdi',
})

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter,
  {
    symbol: '$',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
  })

Vue.prototype.$mp = new MercadoPago(process.env.VUE_APP_TUPIBOX_PAYMENT_PUBLIC_KEY, {
  locale: 'es'
})
Vue.prototype.$bricksBuilder = Vue.prototype.$mp.bricks();
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
