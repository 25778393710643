<template>
    <div>
        
        <div class="form">
            <div class="titleStep">
                <h1>Elige tu suscripción.</h1>
                <p>Mientras más meses, más económica cada caja.</p>
            </div>
            <div class="card-selects prices">
                <div class="columns is-multiline" v-if="formData.boxSize === 'Light'">
                    <div class="column is-4">
                        <b-radio v-model="form.plan"
                            @input="setPrice"
                            name="name"
                            native-value="light_x1"
                            @change.native="nextStep"
                            :class="[form.plan === 'light_x1' ? 'active' : '']"
                            >

                            <img src="@/assets/x1.png">
                            <div>
                                <h3>1 mes</h3>
                                <span>{{prices.light_x1 | currency}} / caja</span>
                            </div>
                            
                        </b-radio>
                    </div>
                    <div class="column is-4">
                        <b-radio v-model="form.plan"
                            @input="setPrice"
                            name="name"
                            native-value="light_x3"
                            @change.native="nextStep"
                            :class="[form.plan === 'light_x3' ? 'active' : '']"
                            >

                            <img src="@/assets/x3.png">
                            <div>
                                <h3>3 meses</h3>
                                <span>{{prices.light_x3 | currency}} / caja</span>
                            </div>
                            
                        </b-radio>
                    </div>
                    <div class="column is-4">
                        
                        <b-radio v-model="form.plan"
                            @input="setPrice"
                            name="name"
                            native-value="light_x6"
                            @change.native="nextStep"
                            :class="[form.plan === 'light_x6' ? 'active' : '']"
                            >

                            <div class="ribbon ribbon-top-right"><span>Recomendado</span></div>

                            <img src="@/assets/x6.png">
                            <div>
                                <h3>6 meses</h3>
                                <span>{{prices.light_x6 | currency}} / caja</span>
                            </div>
                            
                        </b-radio>
                    </div>
                    <div class="column is-12 v-column" v-if="$v.form.plan.$error">
                        <p class="help is-danger">Plan inválido</p>
                    </div>
                    
                </div>
                <div class="columns is-multiline" v-if="true">
                    <div class="column is-4">
                        <b-radio v-model="form.plan"
                            @input="setPrice"
                            name="name"
                            native-value="normal_x1"
                            @change.native="nextStep"
                            :class="[form.plan === 'normal_x1' ? 'active' : '']"
                            >

                            <img src="@/assets/x1.png">
                            <div>
                                <h3>1 mes</h3>
                                <span>{{prices.normal_x1 | currency}} / caja</span>
                            </div>
                            
                        </b-radio>
                    </div>
                    <div class="column is-4">
                        <b-radio v-model="form.plan"
                            @input="setPrice"
                            name="name"
                            native-value="normal_x3"
                            @change.native="nextStep"
                            :class="[form.plan === 'normal_x3' ? 'active' : '']"
                            >

                            <img src="@/assets/x3.png">
                            <div>
                                <h3>3 meses</h3>
                                <span>{{prices.normal_x3 | currency}} / caja</span>
                            </div>
                            
                        </b-radio>
                    </div>
                    <div class="column is-4">
                        
                        <b-radio v-model="form.plan"
                            @input="setPrice"
                            name="name"
                            native-value="normal_x6"
                            @change.native="nextStep"
                            :class="[form.plan === 'normal_x6' ? 'active' : '']"
                            >

                            <div class="ribbon ribbon-top-right"><span>Recomendado</span></div>

                            <img src="@/assets/x6.png">
                            <div>
                                <h3>6 meses</h3>
                                <span>{{prices.normal_x6 | currency}} / caja</span>
                            </div>
                            
                        </b-radio>
                    </div>
                    <div class="column is-12 v-column" v-if="$v.form.plan.$error">
                        <p class="help is-danger">Plan inválido</p>
                    </div>
                    
                </div>
            </div>
            <a class="backStep" @click="backStep">Volver</a>

            <div class="disclaimer">
                <h6><b>Si {{formData.petname}} no está 100% satisfecho con su TupiBox, trabajaremos contigo en hacer la mejor opción</b></h6>
                <p><small>Despacho gratuito en todo Santiago de Chile, para regiones tiene un recargo por método de envío. Te contactaremos para que renueves tu plan, pero puedes decidir no seguir con TupiBox cuando quieras.</small></p>
            </div>
            
        </div>
 
    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required} from 'vuelidate/lib/validators'
    import StepMixin from '../../mixins/StepMixin'

    export default {
        props: ['clickedNext', 'currentStep'],
        mixins: [validationMixin, StepMixin],
        data() {
            return {
                form: {
                    plan: '',
                    price : null,
                    originalPrice : null
                }
            }
        },
        computed: {
            formData(){
                return this.$parent.$parent.$parent.formData 
            },
            prices(){
                return this.$parent.$parent.$parent.configs.prices
            }
        },
        validations: {
            form: {
                plan: { required },
            }
        },
        methods:{
            setPrice(e){
                let selectedSize = e
                console.log("selectedSize")
                console.log(selectedSize)
                let multiplier = selectedSize.slice(-1)
                this.form.price = this.prices[selectedSize] * multiplier
                this.form.originalPrice = this.prices[selectedSize] * multiplier
            }
        },
        
    }
</script>
