<template>
  <steps></steps>
</template>

<script>
import Steps from '../components/Steps.vue'
// @ is an alias to /src

export default {
  name: 'home',
  components: { Steps }
}
</script>
