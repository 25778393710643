<template>
    <div>
        <div class="form">
            <div class="titleStep">
                <h1>¿Qué tan grande es {{formData.petname}}?</h1>
                <p>Todas las cajas tienen el mismo precio. ¡Puedes cambiar el tamaño cuando quieras!</p>
            </div>
            <div class="card-selects">
                <div class="columns is-multiline pets">
                    <div class="column is-4">
                        <b-radio v-model="form.size"
                            name="name"
                            native-value="Little"
                            @change.native="nextStep"
                            :class="[form.size === 'Little' ? 'active' : '']"
                            class="pet-little"
                            >

                            <img src="@/assets/little.png">
                            <div>
                                <h3>Pequeño</h3>
                                <span>0-10 Kgs</span>
                            </div>
                            
                        </b-radio>
                    </div>
                    <div class="column is-4">
                        <b-radio v-model="form.size"
                            name="name"
                            native-value="Medium"
                            @change.native="nextStep"
                            :class="[form.size === 'Medium' ? 'active' : '']"
                            class="pet-medium"
                            >

                            <img src="@/assets/medium.png">
                            <div>
                                <h3>Mediano</h3>
                                <span>10-20 Kgs</span>
                            </div>
                            
                        </b-radio>
                    </div>
                    <div class="column is-4">
                        <b-radio v-model="form.size"
                            name="name"
                            native-value="Big"
                            @change.native="nextStep"
                            :class="[form.size === 'Big' ? 'active' : '']"
                            class="pet-big"
                            >

                            <img src="@/assets/big.png">
                            <div>
                                <h3>Grande</h3>
                                <span>+21 Kgs</span>
                            </div>
                            
                        </b-radio>
                    </div>
                    <div class="column is-12 v-column" v-if="$v.form.size.$error">
                        <p class="help is-danger">Tamaño inválido</p>
                    </div>
                </div>
            </div>
            <a class="backStep" @click="backStep">Volver</a>
        </div>

    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required} from 'vuelidate/lib/validators'
    import StepMixin from '../../mixins/StepMixin'

    export default {
        props: ['clickedNext', 'currentStep'],
        mixins: [validationMixin, StepMixin],
        data() {
            return {
                form: {
                    size: '',
                }
            }
        },
        computed: {
            formData(){
                return this.$parent.$parent.$parent.formData 
            }
        },
        validations: {
            form: {
                size: { required },
            }
        },
    }
</script>