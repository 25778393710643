<template>
  <div>
    <div class="form">
      <div class="titleStep">
        <h1>¿Cuál es tu email?</h1>
        <p>Para el recibo e información importante</p>
      </div>
      <div class="wrap">
        <div class="columns is-multiline">
          <div class="column is-12">
            <b-field>
              <b-input
                v-model.trim="form.email"
                placeholder="Email..."
              ></b-input>
            </b-field>
          </div>
          <div class="column is-12 v-column" v-if="$v.form.email.$error">
            <p class="help is-danger">Email inválido</p>
          </div>
          <div class="column is-12">
            <div class="field text-left">
              <b-checkbox v-model="form.updates"
                >Suscribirme a novedades de TupiBox</b-checkbox
              >
            </div>
          </div>
        </div>
      </div>
      <b-button
        id="step-seven-continue"
        type="is-primary nextButton"
        @click="sendPreCheckout"
        >Continuar</b-button
      >
      <a class="backStep" @click="backStep">Volver</a>
      <div
        style="
          background-color: #f0f0f0;
          padding: 10px;
          margin-top: 20px;
          border-radius: 5px;
        "
      >
        <p style="font-size: 0.9em">
          🎉 ¡Completa este formulario y participa por una TupiBox mensual
          gratis! 🎁<br />
          📣 Anunciaremos la ganadora en nuestro Instagram:
          <a href="https://www.instagram.com/tupibox" style="color: #8a2be2"
            >@tupibox</a
          >
          📸
        </p>
      </div>
      <!-- <div class="msg-sub">
By clicking <a href="#" target="\_blank">"Continue"</a>, you agree to our Terms and <a href="#" target="\_blank">Privacy Policy.</a>
</div> -->
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import StepMixin from "../../mixins/StepMixin";
import crypto from "crypto";
import shortid from "shortid";
import uuidv4 from "uuid/v4";

export default {
  props: ["clickedNext", "currentStep"],
  mixins: [validationMixin, StepMixin],
  data() {
    return {
      payload: {},
    };
  },
  computed: {
    formData() {
      return this.$parent.$parent.$parent.formData;
    },
  },
  validations: {
    form: {
      email: { required, email },
      updates: {},
    },
  },
  methods: {
    async sendPreCheckout() {
      this.nextStep();
      const toSend = {
        ...this.$parent.$parent.$parent.formData,
        url: window.location.href,
      };
      /* pre-checkout webhook */
      //let req = await this.axios({
      //  method: "post",
      //  url: "https://hook.integromat.com/5kwu37y35pi45awq9bixdrir6m5rsvdx",
      //  data: toSend,
      //});
    },
  },
};
</script>
