let mixin = {
    data(){
        return{
            activeStep: 0,
            form : {
                isCompleted: false
            }
        }
    },
    watch: {
        $v: {
            handler: function (val) {
                if(!val.$invalid) {
                    this.$emit('can-continue', {value: true});
                    this.parentComputed.formData = {
                        ...this.parentComputed.formData,
                        ...this.form
                    }
                }
            },
            deep: true
        },
    
    },
    computed: {
        parentComputed(){
            return this.$parent.$parent.$parent
        }
    },
    methods : {
        nextStep(){
            this.$v.$touch()
            if (this.$v.$invalid) {
                
            } else {
                this.parentComputed.nextStep()
                
            }
        },
        backStep(){
            this.parentComputed.backStep()
        }
        
    }

}

export default mixin
