<template>
  <div>
    <div class="form">
      <div class="titleStep">
        <h1>Elige el tipo de caja para tu perro</h1>
        <p>Selecciona qué tipo de productos prefieres para tu mascota</p>
      </div>

      <div class="wrap">
        <div class="columns is-multiline">
          <div class="column is-6">
            <b-radio
              v-model="form.product_type"
              name="name"
              native-value="toys"
              :class="[form.product_type === 'toys' ? 'active' : '']"
            >
              Juguetes y snack
            </b-radio>
          </div>
          <div class="column is-6">
            <b-radio
              v-model="form.product_type"
              name="name"
              native-value="hygiene"
              :class="[form.product_type === 'hygiene' ? 'active' : '']"
            >
              Higiene y salud
            </b-radio>
          </div>
          <div class="column is-12 v-column" v-if="$v.form.product_type.$error">
            <p class="help is-danger">Tipo de producto inválido</p>
          </div>
        </div>
      </div>
      <b-button
        id="step-one-continue"
        type="is-primary nextButton"
        @click="nextStep"
        >Continuar</b-button
      >
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import StepMixin from "../../mixins/StepMixin";
import crypto from "crypto";

export default {
  props: ["clickedNext", "currentStep"],
  mixins: [validationMixin, StepMixin],
  data() {
    return {
      form: {
        product_type: "",
      },
      payload: {},
    };
  },
  computed: {
    formData() {
      return this.$parent.$parent.$parent.formData;
    },
  },
  validations: {
    form: {
      product_type: { required },
    },
  },
  methods: {},
};
</script>
