<template>
    <div>

        <div class="form">
            <div class="titleStep">
                <h1>¿Qué raza es {{formData.petname}}?</h1>
                <p>Si no sabes puedes poner mestizo :)</p>
            </div>
            <div class="wrap">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <b-field>
                            <b-autocomplete
                                v-model="form.breed"
                                :data="filteredDataArray"
                                :open-on-focus="true"
                                placeholder="Escribe para buscar...">
                                <template slot="empty">No results found</template>
                            </b-autocomplete>
                        </b-field>
                    </div>
                    <div class="column is-12 v-column" v-if="$v.form.breed.$error">
                        <p class="help is-danger">Razá inválida</p>
                    </div>
                </div>
            </div>
            <b-button id="step-three-continue" type="is-primary nextButton" @click="nextStep">Continuar</b-button>
            <b-button id="step-three-skip" type="is-primary nextButton" @click="nextStep" outlined>Saltar</b-button>
            <a class="backStep" @click="backStep">Volver</a>
        </div>
    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required} from 'vuelidate/lib/validators'
    import StepMixin from '../../mixins/StepMixin'

    export default {
        props: ['clickedNext', 'currentStep'],
        mixins: [validationMixin, StepMixin],
        data() {
            return {
                form: {
                    breed: '',
                },
                selected: null
            }
        },
        computed: {
            filteredDataArray() {
                return this.breeds.filter((option) => {
                    return option
                        .toString()
                        .toLowerCase()
                        .indexOf(this.form.breed.toLowerCase()) >= 0
                })
            },
            formData(){
                return this.$parent.$parent.$parent.formData 
            },
            breeds(){
                return this.$parent.$parent.$parent.configs.breeds
            }
        },
        validations: {
            form: {
                breed: {  },
            }
        }
    }
</script>
