<template>
<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
</template>

<script>
import Steps from '../components/Steps.vue'
// @ is an alias to /src

export default {
  name: 'loader',
}
</script>
