<template>
  <div class="bar"></div>
</template>
<script>
import { TimelineLite } from "gsap"
export default {
  name: "form-progress",
  props: ["activeField", "formLength"],
  computed: {
    percent() {
      return (this.activeField / this.formLength) * 100
    }
  },
  watch: {
    percent(newVal) {
      let el = document.getElementsByClassName("bar")
      el[0].style.width = newVal + "%"
    }
  }
}
</script>