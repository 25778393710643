
<template>
    <div>

        <div class="form">
            <div class="titleStep">
                <h1>¡Haz a {{formData.petname}} aun más feliz!</h1>
                <p>Agrega un juguete PREMIUM a tu caja por sólo {{prices.extratoy | currency}} adicional al mes.</p>
            </div>

            <div class="wrap">
                <img src="@/assets/extratoy.png" alt="">
                <div class="columns is-multiline">
                    <div class="column is-6">
                        <b-radio v-model="form.extraToy"
                            @input="setPrice"
                            name="name"
                            native-value="Si"
                            @change.native="nextStep"
                            :class="[form.extraToy === 'Si' ? 'active' : '']"
                            >
                            Si 😀
                        </b-radio>
                    </div>
                    <div class="column is-6">
                        <b-radio v-model="form.extraToy"
                            @input="setPrice"
                            name="name"
                            native-value="No"
                            @change.native="nextStep"
                            :class="[form.extraToy === 'No' ? 'active' : '']"
                            >
                            No, gracias
                        </b-radio>
                    </div>
                    <div class="column is-12 v-column" v-if="$v.form.extraToy.$error">
                        <p class="help is-danger">Selección inválida</p>
                    </div>
                </div>
            </div>
            <a class="backStep" @click="backStep">Volver</a>
        </div>

    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required} from 'vuelidate/lib/validators'
    import StepMixin from '../../mixins/StepMixin'
    import crypto from 'crypto'

    export default {
        props: ['clickedNext', 'currentStep'],
        mixins: [validationMixin, StepMixin],
        data() {
            return {
                form: {
                    extraToy: '',
                },
            }
        },
        computed: {
            formData(){
                return this.$parent.$parent.$parent.formData
            },
            prices(){
                return this.$parent.$parent.$parent.configs.prices
            }
        },
        validations: {
            form: {
                extraToy: { required },
            }
        },
        methods: {
            setPrice(e){
                let multiplier = this.formData.plan.slice(-1)
                if(this.form.extraToy === 'Si'){
                    this.formData.price = this.formData.originalPrice + (this.prices.extratoy * multiplier)
                }else{
                    this.formData.price = this.formData.originalPrice
                }
            }
        },
    }
</script>