<template>
    <div>
        
        <div class="form">
            <div class="titleStep">
                <h1>¿Cuándo es el cumpleaños de {{formData.petname}}?</h1>
                <p>O su día de adopción. ¡Queremos celebrar su cumpleaños con una sorpresa!</p>
            </div>
            <div class="wrap">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <b-field>
                            <b-datepicker
                                v-model="form.birth"
                                placeholder="Click para seleccionar."
                                icon="calendar-today"
                            >
                            </b-datepicker>
                        </b-field>
                    </div>
                    <!-- <div class="column is-12 v-column" v-if="$v.form.birth.$error">
                        <p class="help is-danger">This birth is invalid</p>
                    </div> -->
                </div>
            </div>
            <b-button id="step-four-continue" type="is-primary nextButton" @click="nextStep">Continuar</b-button>
            <b-button id="step-four-skip" type="is-primary nextButton" @click="nextStep" outlined>Saltar</b-button>
            <a class="backStep" @click="backStep">Volver</a>
        </div>
    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required} from 'vuelidate/lib/validators'
    import StepMixin from '../../mixins/StepMixin'

    export default {
        props: ['clickedNext', 'currentStep'],
        mixins: [validationMixin, StepMixin],
        data() {
            return {
                form: {
                    birth: null,
                }
            }
        },
        computed: {
            formData(){
                return this.$parent.$parent.$parent.formData 
            }
        },
        validations: {
            form: {
                birth: {  },
            }
        },
    }
</script>